<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="content">
            <div class="a-flex-cfsfs">
                <inspection-edit-form ref="inspectionEdit" @submit='handleSubmit'></inspection-edit-form>
            </div>
            <div style="height: 100px;"></div>
            <div class="a-line-t-e0 footerBox">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即提交</el-button>
                <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="cancelSubmit">&nbsp;&nbsp;&nbsp;返回&nbsp;&nbsp;&nbsp;</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
    import inspectionEditForm from './child/inspection-edit-form.vue';
    export default {
        components: { inspectionEditForm },
        data() {
            return {
                form: {
                },
            };
        },
        created () {
            // this.groupId = this.$route.query.groupId
            // if(this.groupId) this.getWarningGroupDetails()
        },
        computed: {
            
        },
        mounted () {
            
        },
        methods: {
            handleSubmit (reqData) {
                this.$Axios._post({
                    url: this.$Config.apiUrl.inspectionEdit,
                    method: "post",
                    params: reqData,
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.$message.success('操作成功')
                        this.$router.back()
                        this.$store.dispatch("delView", this.$route);
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{

                })
            },
            submit () {
                this.$refs['inspectionEdit'].submit()
            },
            cancelSubmit () {
                this.$confirm('是否确认返回？','温馨提示')
                .then(_=>{
                    this.$router.back()
                    this.$store.dispatch("delView", this.$route);
                })
                .catch(_=>{})
            },
        },
    }
</script>

<style lang="scss" scoped>
    .content{
        font-size: 14px;
        height: 100%;
        overflow-y: auto;
    }
</style>